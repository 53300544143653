.Button {
    /* background: linear-gradient(to right, #9B1C1B, #E87426)!important; */
    background: linear-gradient(to right,  #264AA3,rgb(148, 175, 245))!important;


  }
   .MuiDataGrid-columnHeaders {
    height: 30px !important; 
   min-height: 20px !important;
    
  }
 